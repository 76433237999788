// colors
$background: #182c51;
$moon: $primary;
$peak: #efdbd8;

.moon-animation {
  position: relative;
  width: 512px;
  height: 512px;
}

.moon {
  background-image: radial-gradient(circle at 50% 50%, #fff, #dadada);
  border-radius: 50%;
  z-index: 2;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  &:before {
    content: "";
    position: absolute;
    top: 1%;
    left: 5%;
    width: 90%;
    height: 90%;
    border-radius: 50%;
    background: radial-gradient(
      circle at 50% 0px,
      #ffffff,
      rgba(255, 255, 255, 0) 58%
    );
    filter: blur(5px);
    z-index: 2;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 10%;
    left: 10%;
    border-radius: 50%;
    background: radial-gradient(
      circle at 50% 50%,
      #fff,
      rgba(255, 255, 255, 0.8) 20%,
      rgba(255, 255, 255, 0) 24%
    );
    transform: translateX(-80px) translateY(-90px) skewX(-20deg);
    filter: blur(10px);
  }
}
.eclipse {
  background-image: linear-gradient(45deg, $primary, $secondary);
  top: 0;
  left: 0;
  position: absolute;
  display: block;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 1;
  animation: 3.2s cresent linear infinite alternate;
}

@media only screen and (max-width: 840px) {
  .moon-animation {
    width: 256px;
    height: 256px;
  }
}

// keyframes
@keyframes cresent {
  0% {
    transform: translate(-15px, 15px) scale(0.9);
    box-shadow: 0 0 10px lighten($primary, 5%), 0 0 80px 8px $primary;
  }
  50% {
    transform: translate(0px, 0px) scale(1.02);
    box-shadow: 0 0 10px lighten($moon, 30%), 0 0 80px 8px $moon;
    background-color: $peak;
  }
  100% {
    transform: translate(15px, -15px) scale(0.9);
    box-shadow: 0 0 10px lighten($secondary, 5%), 0 0 80px 8px $secondary;
  }
}
